import React, { useState } from 'react';
import image1 from './Squad/Team/Without BG/Amandeep Lakra_01.png';
import image2 from './Squad/Team/Without BG/Arshdeep Singh_01.png';
import image3 from './Squad/Team/Without BG/Arthur De Sloover_01.png';
import image4 from './Squad/Team/Without BG/Bikramjit Singh_01.png';
import image5 from './Squad/Team/Without BG/Darshan Vibhav Gawkar_01.png';
import image6 from './Squad/Team/Without BG/Devindar Sunil Walmiki_01.png';
import image7 from './Squad/Team/Without BG/Irengbam Rohit_01.png';
import image8 from './Squad/Team/Without BG/Dominic Dixon_01.png';
import image9 from './Squad/Team/Without BG/Maico Casella_01.png';
import image10 from './Squad/Team/Without BG/Mukul Sharma_01.png';
import image11 from './Squad/Team/Without BG/Rahim Aakib Sayyed_01.png';
import image12 from './Squad/Team/Without BG/Rajinder singh_01.png';
import image13 from './Squad/Team/Without BG/Sundaram Rajawat_01.png';
import image14 from './Squad/Team/Without BG/Talwinder Singh_01.png';
import image16 from './Squad/Team/Without BG/Vikas Dahiya_01.png';
import image17 from './Squad/Team/Without BG/akshay-avhad_01.png';
import image18 from './Squad/Team/Without BG/gonzalo peillat_01.png';
import image19 from './Squad/Team/Without BG/Nicholas Woods_01.png';
import image20 from './Squad/Team/Without BG/nilakanta sharma_01.png';
import image21 from './Squad/Team/Without BG/shilanand lakra_01.png';
import image22 from './Squad/Team/Without BG/sumit walmiki_01.png';
import image23 from './Squad/Team/Without BG/Jacob Anderson_01.png';
import image24 from './Squad/Team/Without BG/timothy daniel brand_01.png';
import image25 from './Squad/Team/Without BG/zachary wallace_01.png';

import selectedImage1 from './Squad/Team/With BG/Amandeep Lakra.png';
import selectedImage2 from './Squad/Team/With BG/Arshdeep Singh.png';
import selectedImage3 from './Squad/Team/With BG/Arthur De Sloover.png';
import selectedImage4 from './Squad/Team/With BG/Bikramjit Singh.png';
import selectedImage5 from './Squad/Team/With BG/Darshan Vibhav Gawkar.png';
import selectedImage6 from './Squad/Team/With BG/Devindar Sunil Walmiki.png';
import selectedImage7 from './Squad/Team/With BG/Irengbam Rohit.png';
import selectedImage8 from './Squad/Team/With BG/Dominic Dixon.png';
import selectedImage9 from './Squad/Team/With BG/Maico Casella.png';
import selectedImage10 from './Squad/Team/With BG/Mukul Sharma.png';
import selectedImage11 from './Squad/Team/With BG/Rahim Aakib Sayyed.png';
import selectedImage12 from './Squad/Team/With BG/Rajinder singh.png';
import selectedImage13 from './Squad/Team/With BG/Sundaram Rajawat.png';
import selectedImage14 from './Squad/Team/With BG/Talwinder Singh.png';
import selectedImage16 from './Squad/Team/With BG/Vikas Dahiya.png';
import selectedImage17 from './Squad/Team/With BG/akshay-avhad.png';
import selectedImage18 from './Squad/Team/With BG/gonzalo peillat.png';
import selectedImage19 from './Squad/Team/With BG/Nicholas Woods.png';
import selectedImage20 from './Squad/Team/With BG/nilakanta sharma.png';
import selectedImage21 from './Squad/Team/With BG/shilanand lakra.png';
import selectedImage22 from './Squad/Team/With BG/sumit walmiki.png';
import selectedImage23 from './Squad/Team/With BG/Jacob Anderson.png';
import selectedImage24 from './Squad/Team/With BG/timothy daniel brand.png';
import selectedImage25 from './Squad/Team/With BG/zachary wallace.png';

import { Link } from 'react-router-dom';

const Squad = () => {
  const players = [  
    {
      name: 'Amandeep Lakra',
      role: 'Defender',
      thumbnail: image1,
      image: selectedImage1,  
      details: {
        name: 'Amandeep Lakra',
        position: 'Defender',
        description:"Son of Bijoy Kumar Lakra, one of the biggest coaches in Odisha. The father and son duo won the National Championship a few weeks ago. Amandeep is from the other big academy in Rourkela, the Panposh Hockey Academy. Has been a big year for Aman, won the National Championship as well as made his debut for the national team.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Arshdeep Singh',
      role: 'Forward',
      thumbnail: image2,
      image: selectedImage2,  
      details: {
        name: 'Arshdeep Singh',
        position: 'Forward',
        description:"One of the most exciting young players in the country today. Just won the Asia Cup in Muscat, and is sure to get into the senior team in the next few years. Is from Amritsar and one of the 4 young players (U21) in the team today.  Is a product of the Roundglass Academy which has made tremendous efforts in grassroots hockey in Punjab.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Arthur De Sloover',
      role: 'Defender',
      thumbnail: image3,
      image: selectedImage3,  
      details: {
        name: 'Arthur De Sloover',
        position: 'Defender',    
        description:" Lives and trains in Antwerp where the Belgian Hockey Performance Centre is. Is a World and Olympic Champion with Belgium and only one of the two we have in the team along with Gonzalo. Currently playing Club hockey in Holland for Oranje Rood. Not married and loves Rolex watches. Will definitely be part of the leadership group. ",
        stats: {
          Height: 19,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Bikramjit Singh',
      role: 'Goalkeeper',
      thumbnail: image4,
      image: selectedImage4,  
      details: {
        name: 'Bikramjit Singh',
        position: 'Goalkeeper',
        description:" The best u-21 goalkeeper in India. From Chandigarh and developing extremely well. Alon with Arshdeep, just won the Jr Asia Cup in Oman. Highly rated by Sreejesh.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Darshan Gawkar',
      role: 'Midfielder',
      thumbnail: image5,
      image: selectedImage5,  
      details: {
        name: 'Darshan Vibhav Gawkar',
        position: 'Midfielder',
        description:"Son of a photographer and the other Malad boy in the team. Grew up with Akshay and the two rose together. Extremely talented forward and currently employed with Western Railway in Mumbai and plays for one of the best teams in domestic hockey, RSPB.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Devindar Sunil Walmiki',
      role: 'Defender',
      thumbnail: image6,
      image: selectedImage6,  
      details: {
        name: 'Devindar Sunil Walmiki',
        position: 'Defender',
        description:"Brother of Yuvraj Walmiki who also played for India. Both the brothers came through extremely tough circumstances in Mumbai to become Olympians. One of the senior players in the group and is part of BPCL and is captain of PSPB which is one of the top teams in the country. Highest following on social media amongst all the players in the team.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Irengbam Rohit',
      role: 'Forward',
      thumbnail: image7,
      image: selectedImage7,  
      details: {
        name: 'Irengbam Rohit',
        position: 'Forward',
        description:"One of the two players from Manipur in the team. Youngest player in the team. Currently part of the high Performance Programme in Bhubaneswar. Athletically, one of the best players in the team despite his age. ",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Dominic Dixon',
      role: 'Goalkeeper',
      thumbnail: image8,
      image: selectedImage8,  
      details: {
        name: 'Dominic Dixon',
        position: 'Goalkeeper',
        description:"Also plays in Germany with Tim Brand and Nic Woods. Is having an amazing season. Both for his Club Team and the National Side. One of the two New Zealand boys in the team. Not married and doesn’t have any kids. He comes to India having had an amazing Paris Olympics.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Maico Casella',
      role: 'Midfielder',
      thumbnail: image9,
      image: selectedImage9,  
      details: {
        name: 'Maico Casella',
        position: 'Midfielder',
        description:"Captain of Argentina and our second option from dragflciks after Gonzalo Peillat. Is from Buenos Aires and supports River Plate football club. Currently playing for Gantoise in Belgium and is recovering from an injury. One of the most exciting players in world hockey. ",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Mukul Sharma',
      role: 'Defender',
      thumbnail: image10,
      image: selectedImage10,  
      details: {
        name: 'Mukul Sharma',
        position: 'Defender',
        description:" Mukul is from Delhi and plays for South Central Railway. Our training camp was actually his home base. Our coaches are extremely impressed with his athletic ability. Not married and lives in Hyderabad.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Aakib Rahim',
      role: 'Midfielder',
      thumbnail: image11,
      image: selectedImage11,
      details: {
        name: 'Aakib Rahim ',
        position: 'Midfielder',
        description:"Aakib comes from a hockey playing family from Nagpur. His elder brother Niyaz plays for South Central Railway. Aakib plays for the Indian Navy and has been a  Navyman for a few years now. Powerful midfielder who has represented Maharashtra and Navy for years now.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Rajinder Singh',
      role: 'Midfielder',
      thumbnail: image12,
      image: selectedImage12,
      details: {
        name: 'Rajinder Singh',
        position: 'Midfielder',
        description:"From Sirsa, Haryana. Product of the Namdhari Hockey Academy in Haryana. Widely considered the next big midfielder from India. Plays, moves and idolises former India Captain and his mentor, Sardar Singh. Has family in Australia.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Sundaram Rajawat',
      role: 'Defender',
      thumbnail: image13,
      image: selectedImage13,
      details: {
        name: 'Sundaram Rajawat',
        position: 'Defender',
        description:"Much like Nilakanta, product of the MP Academy in Madhya Pradesh. Hometown is Itarsi. One of the 4 U-21 boys in the team. Lives and trains in Bhopal.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Talwinder Singh',
      role: 'Forward',
      thumbnail: image14,
      image: selectedImage14,
      details: {
        name: 'Talwinder Singh',
        position: 'Forward',
        description:"From Jalandhar. Employed with Indian Oil Corp and plays for them. One of the senior players in the group and a very dangerous striker who knows where the goal is. One of the many PSPB players in the group.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Vikas Dahiya',
      role: 'Goalkeeper',
      thumbnail: image16,
      image: selectedImage16,
      details: {
        name: 'Vikas Dahiya',
        position: 'Goalkeeper',
        description:" Went to the Olympics in Rio. Junior World Cup winner with Sumit. Currently employed with BPCL and plays there with Dev and Shilanand. From Sonipat Haryana. ",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Akshay Ravindra Avhad',
      role: 'Defender',
      thumbnail: image17,
      image: selectedImage17,
      details: {
        name: 'Akshay Ravindra Avhad',
        position: 'Defender',   
        description:"Son of a police inspector. From Mumbai. Comes from the hockey hotbed of Malad. Completed his graduation and is now pursuing further studies. Academically bright.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Gonzalo Peillat',
      role: 'Defender',
      thumbnail: image18,
      image: selectedImage18,
      details: {
        name: 'Gonzalo Peillat',
        position: 'Defender',
        description:"Gonzalo was born in Argentina and won the Olympic Gold with them before he was part of a group of players that fell out with the federation. He moved to Germany and became a World Champion with them. The only player to achieve this feat. He is married to Flora Habif, international hockey player and sister of Thomas Habif, Gonzalo’s former team-mate and an international hockey player himself. He became a father a few months ago. They are now all based in Germany at Mannheimer Hockey Club where Robin is the scientific advisor.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Nicholas Woods',
      role: 'Defender',
      thumbnail: image19,
      image: selectedImage19,
      details: {
        name: 'Nicholas Woods',
        position: 'Defender',
        description:"One of the most experienced players in the group. Currently playing in Germany for Hamburg with 2 other players in our team, Dom Dixon and Tim Brand. Is from New Zealand and one of the 4 players in the team who has captained his national team. Is going to play a key role for us in midfield.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Nilakanta Sharma',
      role: 'Midfielder',
      thumbnail: image20,
      image: selectedImage20,
      details: {
        name: 'Nilakanta Sharma',
        position: 'Midfielder',
        description:"The only Indian player in the team who has kids. Currently employed in Imphal as a Class 1 Officer with the State Govt there. He and Sumit go back a long way and 10 years ago won the Junior World Cup for India together before winning India’s first medal at the Olympics in 42yrs. Very reserved. But an amazing personality on and off the pitch once you get to know him. Highly respected by his peers and definitely one of the leaders in the team. Product of Madhya Pradesh Hockey Academy. ",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Shilanand Lakra',
      role: 'Forward',
      thumbnail: image21,
      image: selectedImage21,
      details: {
        name: 'Shilanand Lakra',
        position: 'Forward',
        description:"One of the two players in the team from Rourkela. The other being Amandeep Lakra. Is having an outstanding season where he spearheaded the attack for Odisha as they went on to win the national championship for the first time. Scored a hattrick in the final. Back in the Indian team as well after he suffered a horrific injury. A product of the SAIL Academy in Rourkela. One of the two big academies in the city.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Sumit Walmiki',
      role: 'Midfielder',
      thumbnail: image22,
      image: selectedImage22,
      details: {
        name: 'Sumit Walmiki',
        position: 'Midfielder',
        description:"Undoubtedly the leader amongst the Indians and and the most decorated Indian player in the team with two Olympic medals. The son of a driver, Sumit is the best defender in his position in the country today. Not married but soon to be. His partner is International hockey player Sonika. Employed with ONGC and plays for them in domestic hockey.  From Sonipat in Haryana. Won his first Olympic medal after tragically losing his mother weeks before the Games. Mentally one of the strongest individuals you are likely to meet.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Jacob Anderson',
      role: 'Forward',
      thumbnail: image23,
      image: selectedImage23,
      details: {
        name: 'Jacob Anderson',
        position: 'Forward',
        description:"Jacob is one of the two Australian in the team. Also plays striker just like Tim Brand. Recently married. Has a BSc in Athletic Performance. When not playing  hockey, Jacob is a qualified Strength and Conditioning Coach and a Personal Trainer. Recently married and came into the team as a replacement player for Terrence Pieters.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Timothy Daniel',
      role: 'Forward',
      thumbnail: image24,
      image: selectedImage24,
      details: {
        name: 'Timothy Daniel',
        position: 'Forward',
        description:"Tim is from Sydney, Australia. Trains and lives in either Perth where the national team is based or Hamburg in Germany where he plays Club hockey. Has dual citizenship. Carries a Dutch and an Australian passport. One of the most explosive forwards in world hockey right now and one of the most important players in the team.",
        stats: {  
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Zach Wallace',
      role: 'Midfielder',
      thumbnail: image25,
      image: selectedImage25,
      details: {
        name: 'Zach Wallace',
        position: 'Midfielder',  
        description:"One of the best midfielders in the world right now and an FIH World Player of the year nominee. Plays and lives with his partner in the Netherlands. Plays for one of the best clubs there, Bloemendaal. Comes into the HIL with a busy schedule behind him. Was in Argentina until a few days agorepresenting England in the FIH Pro League. Was the last foreigner to join the group.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    // Add more players as needed
  ];

  const [selectedPlayer, setSelectedPlayer] = useState(players[0]);

  const handlePlayerClick = (player) => {
    setSelectedPlayer(player);
  };

  return (
    <section className='bg-[#3b0067] md:pt-6 justify-center items-center mx-auto m-2 md:my-10 md:px-[9rem]'>
      <h1 className='md:text-5xl text-3xl text-white text-center font-bold mb-7'>Squad</h1>
      <div className='flex flex-col lg:flex-row gap-5 h-auto'>
      <div className='flex flex-col w-full justify-center lg:w-1/3'>
          <div className='h-96 overflow-y-auto'>
            {players.map((player, index) => (
              <div 
                key={index} 
                className='flex items-center p-2 rounded-md cursor-pointer'
                onClick={() => handlePlayerClick(player)}
              >
                <img src={player.thumbnail} alt={player.name} className='w-20 h-20 object-fill shadow-2xl rounded-md' />
                <div className='ml-4 text-white'>
                  <h4 className='text-lg font-bold'>{player.name}</h4>
                  <span className='text-sm'>{player.role}</span>
                </div>
              </div>
            ))}
          </div>
          <Link to={'/Squad'}>
          <button className='bg-[#ffbd00] px-5 py-1.5 w-24 text-sm rounded-md text-black font-medium mt-3'>View All</button>
          </Link>
        </div>

        <div className='w-full lg:w-1/3 h-auto'>
          <img src={selectedPlayer.image} alt="News" className='rounded-md' />
        </div>

        <div className='w-full lg:w-1/3 pl-4 pt-10'>
          <h2 className='text-4xl font-bold mb-3 text-[#ffbd00]'>{selectedPlayer.details.name}</h2>
          <h5 className='text-xl text-white mb-4 font-semibold'>{selectedPlayer.details.position}</h5>
          <p className='text-sm text-white mb-4'>{selectedPlayer.details.description}</p>
          {/* <div className='grid grid-cols-4 gap-2 text-white'>
            <div className='text-left '>
              <span className='text-2xl font-extrabold block'>{selectedPlayer.details.stats.TotalP || selectedPlayer.details.stats.Height}</span>
              <span className='text-m font-bold'>{selectedPlayer.details.stats.TPName || selectedPlayer.details.stats.Hname }</span>
            </div>
            <div className='text-left'>
              <span className='text-2xl font-extrabold block'>{selectedPlayer.details.stats.AttackP || selectedPlayer.details.stats.SpikeH}</span>
              <span className='text-m font-bold '>{selectedPlayer.details.stats.APName || selectedPlayer.details.stats.SHname }</span>
            </div>
            <div className='text-left'>
              <span className='text-2xl font-extrabold block'>{selectedPlayer.details.stats.BlockP || selectedPlayer.details.stats.BlockH}</span>
              <span className='text-m font-bold  '>{selectedPlayer.details.stats.BPName || selectedPlayer.details.stats.BHname }</span>
            </div>
            <div className='text-left'>
              <span className='text-2xl font-extrabold block'>{selectedPlayer.details.stats.ServeP}</span>
              <span className='text-m font-bold '>{selectedPlayer.details.stats.SPName}</span>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Squad;
