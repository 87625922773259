import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

import Johnson from './Sponsors/Sponsors/Johnson Logo.png';
import Ganna from './Sponsors/Sponsors/Gaana logo.png';
import Exicom from './Sponsors/Sponsors/exicom logo.png';
import Sharesquare from './Sponsors/Sponsors/sharesqure.png';
import Tvs from './Sponsors/Sponsors/Eurogrip Tyres .png';
import ShivNaresh from './Sponsors/Sponsors/Shivnaresh.png';
import MeraHording from './Sponsors/Sponsors/Merahording.png';

const sponsors = [
  { image: Sharesquare, link: 'https://sharesquare.ae/', target: '_blank' },
  { image: Tvs, link: 'https://tvseurogrip.com/', target: '_blank' },
  { image: Johnson, link: 'https://www.hrjohnsonindia.com/', target: '_blank' },
  { image: Exicom, link: 'https://www.exicom.in/', target: '_blank' },
  { image: ShivNaresh, link: 'https://shivnaresh.in/', target: '_blank' },
  { image: Ganna, link: 'https://gaana.com/', target: '_blank' },
  { image: MeraHording, link: 'http://www.merahoardings.com/', target: '_blank' },
];

const Sponsors = () => {
  return (
    <section className="justify-center items-center mx-auto md:px-[9rem] bg-white">
      <div className="container">
        <h2 className="text-center md:text-5xl text-3xl font-bold p-4 text-[#3b0067]">Sponsors</h2>

        <div className="rounded-lg p-6 flex justify-center items-center">
          <div className="w-full">
            <Swiper
              navigation={true}
              modules={[Navigation]}
              spaceBetween={10}
              slidesPerView={0}
              loop={true}
              className="sponsors-slider"
              breakpoints={{
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 4,
                },
                1024: {
                  slidesPerView: 4,
                },
              }}
            >
              {sponsors.map((sponsor, index) => (
                <SwiperSlide key={index}>
                  <div className="text-center">
                    {sponsor.link ? (
                      <a
                        href={sponsor.link}
                        target={sponsor.target}
                        rel="noopener noreferrer"
                        className="block"
                      >
                        <img
                          src={sponsor.image}
                          alt={`Sponsor ${index + 1}`}
                          className="inline-block w-48 h-24"
                        />
                      </a>
                    ) : (
                      <img
                        src={sponsor.image}
                        alt={`Sponsor ${index + 1}`}
                        className="inline-block w-48 h-24"
                      />
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>


        <style jsx>{`

          .swiper-button-next,
          .swiper-button-prev {
            color: purple;
            width: 20px;
            height: 20px;
          }


          @media (max-width: 768px) {
            .swiper-button-next,
            .swiper-button-prev {
              top: 50%;
              transform: translateY(-50%);
              width: 20px;
              height: 20px;
            }
          }
        `}</style>
      </div>
    </section>
  );
};


export default Sponsors;

