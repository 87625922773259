import React, { useState } from 'react';

// Importing images for players and their hover states
// Attacker
import Amandeep from './Team/With BG/Amandeep Lakra.png';
import Arshdeep from './Team/With BG/Arshdeep Singh.png';
import Arthur from './Team/With BG/Arthur De Sloover.png';
import Bikramjit from './Team/With BG/Bikramjit Singh.png';
import Darshan from './Team/With BG/Darshan Vibhav Gawkar.png';
import Devindar from './Team/With BG/Devindar Sunil Walmiki.png';
import Irengbam from './Team/With BG/Irengbam Rohit.png';
import Domin from './Team/With BG/Dominic Dixon.png';
import Maico from './Team/With BG/Maico Casella.png';
import Mukul from './Team/With BG/Mukul Sharma.png';
import Rahim from './Team/With BG/Rahim Aakib Sayyed.png';
import Rajinder from './Team/With BG/Rajinder singh.png';
import Sundaram from './Team/With BG/Sundaram Rajawat.png';
import Talwinder from './Team/With BG/Talwinder Singh.png';
import Vikash from './Team/With BG/Vikas Dahiya.png';
import akshay from './Team/With BG/akshay-avhad.png';
import gonzalo from './Team/With BG/gonzalo peillat.png';
import nicholas from './Team/With BG/Nicholas Woods.png';
import nilakanta from './Team/With BG/nilakanta sharma.png';
import shilan from './Team/With BG/shilanand lakra.png';
import sumit from './Team/With BG/sumit walmiki.png';
import jacob from './Team/With BG/Jacob Anderson.png';
import timothy from './Team/With BG/timothy daniel brand.png';
import zachary from './Team/With BG/zachary wallace.png';

// Hover Images
import AmandeepHover from './Team/Without BG/Amandeep Lakra_01.png';
import ArshdeepHover from './Team/Without BG/Arshdeep Singh_01.png';
import ArthurHover from './Team/Without BG/Arthur De Sloover_01.png';
import BikramjitHover from './Team/Without BG/Bikramjit Singh_01.png';
import DarshanHover from './Team/Without BG/Darshan Vibhav Gawkar_01.png';
import DevindarHover from './Team/Without BG/Devindar Sunil Walmiki_01.png';  
import IrengbamHover from './Team/Without BG/Irengbam Rohit_01.png';
import DominHover from './Team/Without BG/Dominic Dixon_01.png';
import MaicoHover from './Team/Without BG/Maico Casella_01.png';
import MukulHover from './Team/Without BG/Mukul Sharma_01.png';
import RahimHover from './Team/Without BG/Rahim Aakib Sayyed_01.png';
import RajinderHover from './Team/Without BG/Rajinder singh_01.png';
import SundaramHover from './Team/Without BG/Sundaram Rajawat_01.png';
import TalwinderHover from './Team/Without BG/Talwinder Singh_01.png';
import VikashHover from './Team/Without BG/Vikas Dahiya_01.png';
import akshayHover from './Team/Without BG/akshay-avhad_01.png';
import gonzaloHover from './Team/Without BG/gonzalo peillat_01.png';
import nicholasHover from './Team/Without BG/Nicholas Woods_01.png';
import nilakantaHover from './Team/Without BG/nilakanta sharma_01.png';
import shilanHover from './Team/Without BG/shilanand lakra_01.png';
import sumitHover from './Team/Without BG/sumit walmiki_01.png';
import jacobHover from './Team/Without BG/Jacob Anderson_01.png';
import timothyHover from './Team/Without BG/timothy daniel brand_01.png';
import zacharyHover from './Team/Without BG/zachary wallace_01.png';


// Grid Item Component to display player info
const GridItem = ({ image, name, position, bio, hoverImage, onClick }) => {
  return (
    <div className='relative flex flex-col items-center md:w-1/5 w-full mb-2 gap-4 mx-1'>
      <img 
        src={image} 
        alt={name} 
        className='z-10 cursor-pointer' 
        onClick={() => onClick({ name, position, hoverImage })}
      />
      <span className='font-bold text-xl py-1 z-30'>{name}</span>
      <span className='font-semibold z-30'>{position}</span>
    </div>
  );
};

// Modal Box Component to display player hover image
const ModalBox = ({ player, isVisible, onClose }) => {
  if (!isVisible || !player) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white py-5 px-4 rounded-lg relative mx-auto">
        <button 
          className="absolute top-0 right-1 text-2xl font-bold cursor-pointer" 
          onClick={onClose}
        >
          &times;
        </button>
        <div className='md:w-96 w-auto h-auto mb-4 '>
          <img src={player.hoverImage} alt={player.name} className="w-auto md:h-96 h-auto mb-4" />
          <p className='text-justify text-xl p-3 tracking-tight'>{player.bio}</p>
          <h2 className="text-center font-bold text-xl mb-2">{player.name}</h2>
          <p className="text-center font-semibold">{player.position}</p>
        </div>
      </div>
    </div>
  );
};

// Main Section for the Toofani Squad
const SectionToofaniSquad = () => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);

  // Handle click event to show modal
  const handleClick = (player) => {
    setSelectedPlayer(player);
    setModalVisible(true);
    console.log('Player clicked:', player); // Debugging line
  };

  // Arrays of player data
  const Forward  = [
    { image: Arshdeep, name: 'Arshdeep Singh', position: 'Forward', hoverImage: ArshdeepHover, bio:"One of the most exciting young players in the country today. Just won the Asia Cup in Muscat, and is sure to get into the senior team in the next few years. Is from Amritsar and one of the 4 young players (U21) in the team today. Is a product of the Roundglass Academy which has made tremendous efforts in grassroots hockey in Punjab." },
    { image: Talwinder, name: 'Talwinder Singh', position: 'Forward', hoverImage: TalwinderHover, bio:"From Jalandhar. Employed with Indian Oil Corp and plays for them. One of the senior players in the group and a very dangerous striker who knows where the goal is. One of the many PSPB players in the group." },
    { image: jacob, name: 'Jacob Anderson', position: 'Forward', hoverImage: jacobHover, bio:"Jacob is one of the two Australian in the team. Also plays striker just like Tim Brand. Recently married. Has a BSc in Athletic Performance. When not playing  hockey, Jacob is a qualified Strength and Conditioning Coach and a Personal Trainer. Recently married and came into the team as a replacement player for Terrence Pieters." },
    { image: timothy, name: 'Timothy Daniel', position: 'Forward', hoverImage: timothyHover, bio:"Tim is from Sydney, Australia. Trains and lives in either Perth where the national team is based or Hamburg in Germany where he plays Club hockey. Has dual citizenship. Carries a Dutch and an Australian passport. One of the most explosive forwards in world hockey right now and one of the most important players in the team." },
    { image: shilan, name: 'Shilanand Lakra', position: 'Forward', hoverImage: shilanHover, bio:"One of the two players in the team from Rourkela. The other being Amandeep Lakra. Is having an outstanding season where he spearheaded the attack for Odisha as they went on to win the national championship for the first time. Scored a hattrick in the final. Back in the Indian team as well after he suffered a horrific injury. A product of the SAIL Academy in Rourkela. One of the two big academies in the city." },
    { image: Irengbam, name: 'Irengbam Rohit', position: 'Forward', hoverImage: IrengbamHover, bio:"One of the two players from Manipur in the team. Youngest player in the team. Currently part of the high Performance Programme in Bhubaneswar. Athletically, one of the best players in the team despite his age." },

  ];

  const Midfielder  = [
    { image: Darshan, name: 'Darshan Gavkar', position: 'Midfielder', hoverImage: DarshanHover, bio:"Son of a photographer and the other Malad boy in the team. Grew up with Akshay and the two rose together. Extremely talented forward and currently employed with Western Railway in Mumbai and plays for one of the best teams in domestic hockey, RSPB" },
    { image: nilakanta, name: 'Nilakanta Sharma', position: 'Midfielder', hoverImage: nilakantaHover, bio:"The only Indian player in the team who has kids. Currently employed in Imphal as a Class 1 Officer with the State Govt there. He and Sumit go back a long way and 10 years ago won the Junior World Cup for India together before winning India’s first medal at the Olympics in 42yrs. Very reserved. But an amazing personality on and off the pitch once you get to know him. Highly respected by his peers and definitely one of the leaders in the team. Product of Madhya Pradesh Hockey Academy. " },
    { image: Maico, name: 'Maico Casella', position: 'Midfielder', hoverImage: MaicoHover, bio:"Captain of Argentina and our second option from dragflciks after Gonzalo Peillat. Is from Buenos Aires and supports River Plate football club. Currently playing for Gantoise in Belgium and is recovering from an injury. One of the most exciting players in world hockey." },
    { image: Rahim, name: 'Aakib Rahim', position: 'Midfielder', hoverImage: RahimHover, bio:"Aakib comes from a hockey playing family from Nagpur. His elder brother Niyaz plays for South Central Railway. Aakib plays for the Indian Navy and has been a Navyman for a few years now. Powerful midfielder who has represented Maharashtra and Navy for years now." },
    { image: Rajinder, name: 'Rajinder Singh', position: 'Midfielder', hoverImage: RajinderHover, bio:"From Sirsa, Haryana. Product of the Namdhari Hockey Academy in Haryana. Widely considered the next big midfielder from India. Plays, moves and idolises former India Captain and his mentor, Sardar Singh. Has family in Australia." },
    { image: zachary, name: 'Zach Wallace', position: 'Midfielder', hoverImage: zacharyHover, bio:"One of the best midfielders in the world right now and an FIH World Player of the year nominee. Plays and lives with his partner in the Netherlands. Plays for one of the best clubs there, Bloemendaal. Comes into the HIL with a busy schedule behind him. Was in Argentina until a few days agorepresenting England in the FIH Pro League. Was the last foreigner to join the group." },
    { image: sumit, name:'Sumit Walmiki', position: 'Midfielder', hoverImage: sumitHover, bio:" Undoubtedly the leader amongst the Indians and and the most decorated Indian player in the team with two Olympic medals. The son of a driver, Sumit is the best defender in his position in the country today. Not married but soon to be. His partner is International hockey player Sonika. Employed with ONGC and plays for them in domestic hockey.  From Sonipat in Haryana. Won his first Olympic medal after tragically losing his mother weeks before the Games. Mentally one of the strongest individuals you are likely to meet." },
    
  ];

  const Defender  = [
    { image: akshay, name: 'Akshay Avhad', position: 'Defender', hoverImage: akshayHover,bio:"Son of a police inspector. From Mumbai. Comes from the hockey hotbed of Malad. Completed his graduation and is now pursuing further studies. Academically bright." },
    { image: Amandeep, name: 'Amandeep Lakra', position: 'Defender', hoverImage: AmandeepHover, bio:"Son of Bijoy Kumar Lakra, one of the biggest coaches in Odisha. The father and son duo won the National Championship a few weeks ago. Amandeep is from the other big academy in Rourkela, the Panposh Hockey Academy. Has been a big year for Aman, won the National Championship as well as made his debut for the national team." },
    { image: Devindar, name: 'Dev Walmiki', position: 'Defender', hoverImage: DevindarHover, bio:"Brother of Yuvraj Walmiki who also played for India. Both the brothers came through extremely tough circumstances in Mumbai to become Olympians. One of the senior players in the group and is part of BPCL and is captain of PSPB which is one of the top teams in the country. Highest following on social media amongst all the players in the team." },
    { image: Arthur, name: 'Arthur De Sloover', position: 'Defender', hoverImage: ArthurHover, bio:"Lives and trains in Antwerp where the Belgian Hockey Performance Centre is. Is a World and Olympic Champion with Belgium and only one of the two we have in the team along with Gonzalo. Currently playing Club hockey in Holland for Oranje Rood. Not married and loves Rolex watches. Will definitely be part of the leadership group." },
    { image: gonzalo, name: 'Gonzalo Peillat', position: 'Defender', hoverImage: gonzaloHover, bio:"Gonzalo was born in Argentina and won the Olympic Gold with them before he was part of a group of players that fell out with the federation. He moved to Germany and became a World Champion with them. The only player to achieve this feat. He is married to Flora Habif, international hockey player and sister of Thomas Habif, Gonzalo’s former team-mate and an international hockey player himself. He became a father a few months ago. They are now all based in Germany at Mannheimer Hockey Club where Robin is the scientific advisor." },
    { image: nicholas, name: 'Nicholas Woods', position: 'Defender', hoverImage: nicholasHover, bio:"One of the most experienced players in the group. Currently playing in Germany for Hamburg with 2 other players in our team, Dom Dixon and Tim Brand. Is from New Zealand and one of the 4 players in the team who has captained his national team. Is going to play a key role for us in midfield." },
    { image: Mukul, name: 'Mukul Sharma', position: 'Defender', hoverImage: MukulHover, bio:" Mukul is from Delhi and plays for South Central Railway. Our training camp was actually his home base. Our coaches are extremely impressed with his athletic ability. Not married and lives in Hyderabad." },
    { image: Sundaram, name: 'Sundaram Rajawat', position: 'Defender', hoverImage: SundaramHover, bio:"Much like Nilakanta, product of the MP Academy in Madhya Pradesh. Hometown is Itarsi. One of the 4 U-21 boys in the team. Lives and trains in Bhopal." },
   
  ];

  const goalies = [
    { image: Bikramjit, name: 'Bikramjit Singh', position: 'Goalkeeper', hoverImage: BikramjitHover, bio:"The best u-21 goalkeeper in India. From Chandigarh and developing extremely well. Alon with Arshdeep, just won the Jr Asia Cup in Oman. Highly rated by Sreejesh." },
    { image: Domin, name: 'Dominic Dixon', position: 'Goalkeeper', hoverImage: DominHover, bio:"Also plays in Germany with Tim Brand and Nic Woods. Is having an amazing season. Both for his Club Team and the National Side. One of the two New Zealand boys in the team. Not married and doesn't have any kids. He comes to India having had an amazing Paris Olympics" },
    { image: Vikash, name: 'Vikas Dahiya', position: 'Goalkeeper', hoverImage: VikashHover, bio:" Went to the Olympics in Rio. Junior World Cup winner with Sumit. Currently employed with BPCL and plays there with Dev and Shilanand. From Sonipat Haryana." },
  ];

  return (
    <section className='mb-6'>
      <h1 className='py-4 bg-[#ffbd00] text-center text-4xl font-bold'>Toofani Squad</h1>
      <div className='max-auto md:px-[8rem]'>

        {/* Center Section */}
        <h2 className='text-center md:text-4xl py-5 font-semibold text-[#3b0067] text-2xl'>Forward</h2>
        <div className="flex flex-wrap justify-center gap-5">
          {Forward.map((player, index) => (
            <GridItem key={index} {...player} onClick={handleClick} />
          ))}
        </div>

        {/* Right Wing Section */}
        <h2 className='text-center md:text-4xl py-5 font-semibold text-[#3b0067] text-2xl'>Midfielder</h2>
        <div className="flex flex-wrap justify-center gap-5">
          {Midfielder.map((player, index) => (
            <GridItem key={index} {...player} onClick={handleClick} />
          ))}
        </div>

        {/* Defender Section */}
        <h2 className='text-center md:text-4xl py-5 font-semibold text-[#3b0067] text-2xl'>Defender</h2>
        <div className="flex flex-wrap justify-center gap-5">
          {Defender.map((player, index) => (
            <GridItem key={index} {...player} onClick={handleClick} />
          ))}
        </div>

        {/* Goalie Section */}
        <h2 className='text-center md:text-4xl py-5 font-semibold text-[#3b0067] text-2xl'>Goalkeeper</h2>
        <div className="flex flex-wrap justify-center gap-5">
          {goalies.map((player, index) => (
            <GridItem key={index} {...player} onClick={handleClick} />
          ))}
        </div>
      </div>

      {/* Modal for player info */}
      <ModalBox 
        player={selectedPlayer}
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
      />
    </section>
  );
};

export default SectionToofaniSquad; 
