import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import about1 from './Gallery/Image/About-01.jpg';
import about2 from './Gallery/Image/About-02.jpg';
import about3 from './Gallery/Image/About-03.jpg';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom';
// import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ImageText = () => {
  const images = [
    { src: about1, alt: 'Image 1' },
    { src: about2, alt: 'Image 2' },
    { src: about3, alt: 'Image 3' },
  ];

  return (
    <section className="flex flex-col md:flex-row justify-center items-center mx-auto m-2 mt-10 md:px-[9rem] gap-4">
      <div className="md:w-1/2 w-full h-full overflow-hidden relative bg-[#3b0067] p-7 pb-10 rounded-xl mt-3">
        <div className="swiper-container relative">
          <Swiper 
            modules={[Navigation, Autoplay]}
            navigation={{
              nextEl: '.swiper-button-next-unique',
              prevEl: '.swiper-button-prev-unique'
            }}
            loop={true}
            spaceBetween={10}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            speed={6000}
            className="static"
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="relative w-full h-full mb-5">
                  <img src={image.src} alt={image.alt} className="w-full h-full object-cover rounded-xl" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-button-prev-unique">
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          <div className="swiper-button-next-unique">
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
      <div className="md:w-1/2 w-full p-6 pt-10 md:mt-6 md:text-justify text-left">
        <h2 className='text-[#3b0067] md:text-5xl font-bold pb-5 md:text-left text-center text-3xl'>About Us</h2>
        <p className="md:text-m text-sm pb-3">Resolute Sports Pvt. Ltd. is a sporting venture that aims to revolutionize India's sports landscape with a holistic ecosystem beyond cricket based on the principle of Teamwork, Unity, Discipline and Resilience that impacts not only the stakeholders, but society at large.</p>
        <p className="md:text-xl text-m pb-3 mt-4 font-bold">About Hyderabad Toofans</p>
        <p className="md:text-m text-sm pb-3">Introducing the Hyderabad Toofans, a dynamic hockey team making waves in the Hockey India League—a thrilling competition making its triumphant return after a 7-year hiatus! With a blend of traditional flair and modern strategy, the Toofans aren't here to just compete, but they’re here to conquer. Each and every person part of the Hyderabad Toofans is fueled by a fierce determination and drive to elevate the sport to new heights, pushing the boundaries of the game and setting new standards of excellence. The Toofans are committed to nurturing homegrown talent and transforming them into world-class athletes destined to shine on the international stage. Every tackle, pass, and goal marks a step toward reigniting India’s passion for hockey. Embodying the very essence of sportsmanship and perseverance, the Toofans are here to rewrite the story of Indian hockey. Join the Hyderabad Toofans as they unleash a new era of hockey and captivate the nation like never before!</p>
        <Link to={'/About'} >
        <button className='bg-[#ffbd00] p-3 mt-4 font-bold rounded-md px-5 text-[#3b0067]'>Learn More</button>
       </Link>
      </div>
    </section>
  );
};

export default ImageText;