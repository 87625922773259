import React from 'react';
import { GiCommercialAirplane } from "react-icons/gi";

const PlayersPrice = () => {
  const playersData = [
    { name: "Gonzalo Peillat", position: "Forward", nationality: "Germany"},
    { name: "Dominic Dixon", position: "Forward", nationality: "New Zealand"},
    { name: "Nilakanta Sharma", position: "Forward", nationality: "India"},
    { name: "Shilanand Lakra", position: "Forward", nationality: "India"},
    { name: "Sumit", position: "Forward", nationality: "India"},
    { name: "Jacob Anderson", position: "Forward", nationality: "Australia"},
    { name: "Timothy Daniel", position: "Forward", nationality: "Australia"},
    { name: "Zachary Wallace", position: "Forward", nationality: "United Kingdom"},
    { name: "Amandeep Lakra", position: "Midfielder", nationality: "India"},
    { name: "Arshdeep Singh", position: "Midfielder", nationality: "India"},
    { name: "Arthur De Sloover", position: "Midfielder", nationality: "Belgium"},
    { name: "Bikramjit Singh", position: "Midfielder", nationality: "India"},
    { name: "Darshan Vibhav Gawkar", position: "Midfielder", nationality: "India"},
    { name: "Devindar Sunil Walmiki", position: "Midfielder", nationality: "India"},
    { name: "Irengbam Rohit", position: "Midfielder", nationality: "India"},
    { name: "Nicholas Woods", position: "Midfielder", nationality: "Germany"},
    { name: "Maico Casella", position: "Midfielder", nationality: "Argentina"},
    { name: "Mukul Sharma", position: "Midfielder", nationality: "India"},
    { name: "Rahim Aakib Sayyed", position: "Midfielder", nationality: "India"},
    { name: "Rajinder Singh", position: "Midfielder", nationality: "India"},
    { name: "Sundaram Rajawat", position: "Midfielder", nationality: "India"},
    { name: "Talwinder Singh", position: "Midfielder", nationality: "India"},
    { name: "Vikas Dahiya", position: "Midfielder", nationality: "India"},
    { name: "Akshay Ravindra Avhad", position: "Defender", nationality: "India"},
  ];

  const playersWithIcon = new Set([
    "Gonzalo Peillat",
    "Nicholas Woods",
    "Jacob Anderson",
    "Timothy Daniel",
    "Zachary Wallace",
    "Arthur De Sloover",
    "Dominic Dixon",
    "Maico Casella",
    "Eimily Suzane Caledron Escobar",
    "Pasha Gademan",
    "Robin Anthony Webster Arkell"
  ]);

  const staffData = [
    { name: "Eimily Suzane Caledron Escobar", position: "Coach", nationality: "Belgium"},
    { name: "Pasha Gademan", position: "Head Coach", nationality: "Netherlands"},
    { name: "Sanjay Kumar Bir ", position: "Asst. Coach", nationality: "India"},
    { name: "Robin Anthony Webster Arkell", position: "Asst. Coach", nationality: "Germany"},
    { name: "Arvind Yadav", position: "Physio", nationality: "India"},
    { name: "Karan Pathak", position: "Manager", nationality: "India"},

  ];

  return (
    <section className='text-black py-4 mx-auto md:px-[10rem]'>
      <div className='mx-auto m-2 gap-4 border-t border-gray-300 pb-5 rounded-lg'>
        <h3 className='uppercase bg-[#3B0067] rounded-t-lg px-4 py-2 text-white'>About Players</h3>
        <div className='overflow-x-auto'>
          <table className='min-w-full border-collapse border border-gray-300'>
            <thead>
              <tr className='bg-gray-200'>
                <th className='border border-gray-300 py-2 text-left px-4'>Players</th>
                <th className='border border-gray-300 py-2 text-center px-4'>Position</th>
                <th className='border border-gray-300 py-2 text-center px-4'>Nationality</th>
              </tr>
            </thead>
            <tbody>
              {playersData.map((player, index) => (
                <tr key={index} className='border-b border-gray-300'>
                  <td className='py-2 px-2'>
                    <span className='flex md:gap-3 gap-1 md:font-bold '>{player.name}
                      {playersWithIcon.has(player.name) && <GiCommercialAirplane />}
                    </span>
                  </td>
                  <td className='py-2 px-4 text-center font-semibold md:font-bold'>{player.position}</td>
                  <td className='py-2 px-4 text-center font-semibold md:font-bold'>{player.nationality}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className='mx-auto m-2 gap-4 border-t border-gray-300 pb-5 rounded-lg'>
        <h3 className='uppercase bg-[#3B0067] rounded-t-lg px-4 py-2 text-white'>Team Management</h3>
        <div className='overflow-x-auto'>
          <table className='min-w-full border-collapse border border-gray-300'>
            <thead>
              <tr className='bg-gray-200'>
                <th className='border border-gray-300 py-2 text-left px-4'>Member</th>
                <th className='border border-gray-300 py-2 text-center px-4'>Position</th>
                <th className='border border-gray-300 py-2 text-center px-4'>Nationality</th>
              </tr>
            </thead>
            <tbody>
              {staffData.map((player, index) => (
                <tr key={index} className='border-b border-gray-300'>
                  <td className='py-2 px-2'>
                    <span className='flex md:gap-3 gap-1 md:font-bold '>{player.name}
                      {playersWithIcon.has(player.name) && <GiCommercialAirplane />}
                    </span>
                  </td>
                  <td className='py-2 px-4 text-center font-semibold md:font-bold'>{player.position}</td>
                  <td className='py-2 px-4 text-center font-semibold md:font-bold'>{player.nationality}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default PlayersPrice;