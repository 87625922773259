import React, { useState } from 'react';
import Team_Hyderabad from './Image/Teams/Hyderabad.webp';
import Team_Shrachi from './Image/Teams/SHRACHI.webp';
import Team_Delhi from './Image/Teams/Delhi SG Pipers.webp';
import Team_Gonasika from './Image/Teams/Gonasika.webp';
import Team_Up from './Image/Teams/UP Rudras.webp';
import Team_Soorma from './Image/Teams/JSW.webp';
import Team_Kalinga from './Image/Teams/Kalinga Lancer.webp';
import Team_TamilNadu from './Image/Teams/Tamilnadu.webp';


const MatchCard = ({ team1, team2, firstTeamName, secondTeamName, date, place, result, score, penalty }) => {
  return (
    <div className="text-center py-1 mb-4 w-full shadow-xl">
      <span className="font-bold py-5">{result}</span><br className='mt-5' />
      <span className="font-semibold">{date} | {place}</span>
      <div className="flex justify-evenly items-center gap-5 py-4">
        <img src={team1} alt={firstTeamName} className="w-20 h-auto" />
        {score ? (
          <>
            <div className="grid">
            <span className="text-lg font-bold text-[#3b0067] p-1 rounded-md">{score}</span>
            {penalty? (
              <span className="text-sm font-semibold text-[#3b0067] p-1 rounded-md">{penalty}</span>
            ):(
              null
            )}
            </div>
          </>
        ) : (
          <>
            <span className="text-lg font-bold text-[#3b0067] p-1 rounded-md">Vs</span>
          </>
        )}
        <img src={team2} alt={secondTeamName} className="w-20 h-auto" />
      </div>
      {/* <p className="my-2">
        <span className="px-2 py-1 border rounded-md mb-2">{set}</span>
      </p> */}
      <h2 className="bg-[#3b0067] text-white py-2 rounded-md">
       {firstTeamName} <span className="font-bold px-1">Vs</span> {secondTeamName}
      </h2>
    </div>
  );
};

const Fixture = () => {
  const [visibleMatches, setVisibleMatches] = useState(8); // State to track the number of visible matches

  const matches = [
    { team1: Team_Hyderabad, team2: Team_Shrachi,  firstTeamName: 'Hyderabad Toofans', secondTeamName: 'Shrachi Rarh Bengal Tigers', result: 'League', date: 'Sunday, 29 December 2024', place:'Rourkela', score:'2-3' },
    { team1: Team_Delhi, team2: Team_Hyderabad, firstTeamName: 'Delhi SG Pipers', secondTeamName: 'Hyderabad Toofans', result: 'League', date: 'Tuesday, 31 December 2024', place:'Rourkela', score:'2-2', penalty:'(4)  (5)'  },
    { team1: Team_Hyderabad, team2: Team_Gonasika,  firstTeamName: 'Hyderabad Toofans', secondTeamName: 'Gonasika', result: 'League', date: 'Saturday, 4 January 2025', place:'Rourkela', score:'1-3' },
    { team1: Team_Up, team2: Team_Hyderabad, firstTeamName: 'UP Rudras', secondTeamName: 'Hyderabad Toofans', result: 'League', date: 'Wednesday, 8 January 2025', place:'Rourkela', score:'0-3' },
    { team1: Team_Hyderabad, team2: Team_Soorma,  firstTeamName: 'Hyderabad Toofans', secondTeamName: 'Soorma Hockey Club', result: 'League', date: 'Saturday, 11 January 2025', place:'Rourkela', score:'1-1',penalty:'(4)  (3)' },
    { team1: Team_Kalinga, team2: Team_Hyderabad,  firstTeamName: 'Vedanta Kalinga Lancers', secondTeamName: 'Hyderabad Toofans', result: 'League', date: 'Sunday, 12 January 2025', place:'Rourkela', score:'1-5' },
    { team1: Team_Hyderabad, team2: Team_TamilNadu,  firstTeamName: 'Hyderabad Toofans', secondTeamName: 'Tamil Nadu Dragons', result: 'League', date: 'Saturday, 18 January 2025', place:'Ranchi', score:'4-0' },
    { team1: Team_TamilNadu, team2: Team_Hyderabad,  firstTeamName: 'Tamil Nadu Dragons', secondTeamName: 'Hyderabad Toofans', result: 'League', date: 'Thursday, 23 January 2025', place:'Rourkela', score:'2-2', penalty:'(4)  (3)' },
    { team1: Team_Hyderabad, team2: Team_Up,  firstTeamName: 'Hyderabad Toofans', secondTeamName: 'UP Rudras', result: 'League', date: 'Saturday, 25 January 2025', place:'Rourkela', score:'3-1' },
    { team1: Team_Gonasika, team2: Team_Hyderabad,  firstTeamName: 'Gonasika', secondTeamName: 'Hyderabad Toofans', result: 'League', date: 'Tuesday, 28 January 2025', place:'Rourkela', score:'3-3', penalty:'(3)  (1)' },
    { team1:Team_Soorma, team2:Team_Hyderabad, firstTeamName: 'Soorma Hockey Club', secondTeamName: 'Hyderabad Toofans', result: 'Semi Final', date: 'Friday, 31 January 2025', place:'Rourkela', score:'1-3' },
    { team1:Team_Shrachi, team2:Team_Hyderabad, firstTeamName: 'Shrachi Rarh Bengal Tigers', secondTeamName: 'Hyderabad Toofans', result: 'Final', date: 'Saturday, 1 February 2025', place:'Rourkela', score:'4-3' },
  ];

  const showMoreMatches = () => {
    setVisibleMatches(prev => prev + 8); // Show 8 more matches when the button is clicked
  };

  return (
    <div className="max-auto md:px-[6rem]">
      <div className="grid md:grid-cols-3 items-center justify-center gap-6 p-1 pt-10 md:px-10">
        {matches.slice(0, visibleMatches).map((match, index) => (
          <MatchCard key={index} {...match} />
        ))}
      </div>
      {visibleMatches < matches.length && (
        <div className="text-center py-4">
          <button onClick={showMoreMatches} className="px-4 py-2 bg-[#ffbd00] text-black rounded-md">
            Show More
          </button>
        </div>
      )}
    </div>
  );
};

export default Fixture;
