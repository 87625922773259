import React from 'react'

import sharesqure from '../Sponsors/Sponsors/sharesqure.png'
import tvs from '../Sponsors/Sponsors/Eurogrip Tyres .png'
import johnson from '../Sponsors/Sponsors/Johnson Logo.png'

import gaana from '../Sponsors/Sponsors/Gaana logo.png'
import exicom from '../Sponsors/Sponsors/exicom logo.png'
import ShivNaresh from '../Sponsors/Sponsors/Shivnaresh.png'
import MeraHording from '../Sponsors/Sponsors/Merahording.png'



const SectionSponsors = () => {
  return (
   <section className='md:mx-10 mt-10 mx-1'>
    <div className='bg-white justify-center items-center rounded-t-2xl shadow-[#3b0067] shadow-inner'>
        <h2 className='text-center text-[#3b0067] text-4xl p-3 my-2 font-semibold'>Principal Sponsors</h2>
        <div className='md:flex grid grid-flow-row items-center justify-center gap-4 py-4'>
            <a href='https://sharesquare.ae/' target='_blank' rel="noreferrer">  
                <img src={sharesqure} alt="" /> 
            </a>
        </div>	
        <h2 className='text-center text-[#3b0067] text-4xl p-3 font-semibold'> Official Sponsor</h2>
        <div className='md:flex grid grid-flow-row  items-center justify-center gap-4 py-4'>
            <a href='https://tvseurogrip.com/' target='_blank' rel="noreferrer">
                 <img src={tvs} alt="" />
            </a>
        </div>
        <h2 className='text-center text-[#3b0067] text-3xl p-3 font-semibold'>Associate Sponsors</h2>
        <div className='md:flex grid grid-flow-row  items-center justify-center gap-4 py-4'>
            <a href='https://www.hrjohnsonindia.com/' target='_blank' rel="noreferrer">
                 <img src={johnson} alt="" />
            </a>
            <a href='https://www.exicom.in/' target='_blank' rel="noreferrer">
            <img src={exicom} alt="" />
           </a>
        </div>
        <h3 className='text-center text-[#3b0067] text-2xl p-3 font-semibold'>Apperal Partner</h3>
        <div className='md:flex grid grid-flow-row items-center justify-center gap-5 py-6'>
            <a href='https://shivnaresh.in/' target='_blank' rel="noreferrer">
                <img src={ShivNaresh} alt="" />
            </a>
        </div>
        <h3 className='text-center text-[#3b0067] text-2xl p-3 font-semibold'>FM Radio Partner</h3>
        <div className='md:flex grid grid-flow-row items-center justify-center gap-5 py-6'>
            <a href='https://gaana.com/' target='_blank' rel="noreferrer">
                <img src={gaana} alt="" />
            </a>
        </div>
        <h3 className='text-center text-[#3b0067] text-2xl p-3 font-semibold'>Outdoor Media Partner</h3>
        <div className='md:flex grid grid-flow-row items-center justify-center gap-5 py-6'>
            <a href='http://www.merahoardings.com/' target='_blank' rel="noreferrer">
                <img src={MeraHording} alt="" />
            </a>
        </div>
    </div>
   </section>
  )
}

export default SectionSponsors